import { ColumnSizingState, VisibilityState } from "@tanstack/react-table";
import { TDealer } from "api/dealershipApi";
import { IDateRangeProps } from "components/DateRange";
import { IDropdownFilterKeyPair } from "components/Table";
import { TInventoryOption } from "constants/common";
import { lastWeek } from "constants/commonDateRanges";
import { defaultPageSize } from "constants/defaultPageSizes";
import { options } from "constants/providerFilters";
// import { IUser } from "contexts/AuthContext";
import { IDealer } from "store/auth/authSlice";
import { EResultScoreDateRange } from "types/EResultScoreDateRange";
import { IProviderCheckList } from "types/IProviderCheckList";
import ELocalStorageKeys from "types/LocalStorageKey";

export enum ELocalSavedFilters {

  // Inventory
  SelectedInventoryHeader = "selectedInventoryHeader",

  // ETC Sections
  SelectedDealerHeader = "selectedDealerHeader",

  SelectedDealerHeaderV2 = "selectedDealerHeaderV2",

  // Result Summary Page
  ResultScoreGraph = "resultScoreGraph",
  SalesToFLRTable = "salesToFLRLegendTable",

  // Merchandising Page
  ChannelStatsTable = "channelStatsTable",
  DealRatingsTable = "dealRatingsTable",
  PreShootReportTable = "preShootReportTable",
  SalesToFrontLineReadyGraph = "salesToFrontLineReadyGraph",
  TipReportTable = "tipReportTable",
  TipReportTableV2 = "tipReportTableV2",

  // VDP Results Page
  VDPResultTable = "vdpResultTable",

  // Reporting Page
  FrontLineReadyTrendGraph = "frontLineReadyTrendGraph",
  LeastVDPTable = "leastVDPTable",
  MostVDPTable = "mostVDPTable",
  MostLeadsTable = "mostLeadsTableTable",
  DaysOnLotTable = "daysOnLotTable",
  VDPBoostTable = "vdpBoostTable",
  LeadDetailsV2 = "leadDetailsV2",

  // Marketing Page
  BudgetROITable = "budgetROITable",
  TopTenWebsiteSourcesTable = "topTenWebsiteSourcesTable",
  VDPsByChannelTable = "vdpsByChannelTable",
  VDPROITable = "vdproiTable",
  LeadROITable = "leadROITable",
}

interface ITable {
  dateRange: IDateRangeProps["value"];
  pageSize: number;
  columnVisibility?: VisibilityState;
  columnSizing?: ColumnSizingState;
}

export interface ITableWithProviders extends ITable {
  providers: IProviderCheckList[];
}

export interface ITableTipReport extends Omit<ITable, "dateRange"> {
  source: IDropdownFilterKeyPair;
}

interface ITablePreShootReport extends Omit<ITable, "dateRange"> {}

interface IGraph {
  dateRange: IDateRangeProps["value"];
}

export interface ITableMarketing extends Omit<ITable, "pageSize"> {}

export interface IResultScore {
  filterBy: EResultScoreDateRange;
}

export interface ISavedDefaultFilters {

  // Inventory
  [ELocalSavedFilters.SelectedInventoryHeader]: TInventoryOption;

  // ETC Sections
  [ELocalSavedFilters.SelectedDealerHeader]: IDealer;

  [ELocalSavedFilters.SelectedDealerHeaderV2]: TDealer;

  //Result Summary Page
  [ELocalSavedFilters.ResultScoreGraph]: IResultScore;
  [ELocalSavedFilters.SalesToFLRTable]: IResultScore;

  // Merchandising Page
  [ELocalSavedFilters.ChannelStatsTable]: ITableWithProviders;
  [ELocalSavedFilters.DealRatingsTable]: ITableWithProviders;
  [ELocalSavedFilters.PreShootReportTable]: ITablePreShootReport;
  [ELocalSavedFilters.SalesToFrontLineReadyGraph]: IGraph;
  [ELocalSavedFilters.TipReportTable]: ITableTipReport;
  [ELocalSavedFilters.TipReportTableV2]: ITableWithProviders;

  // VDP Result Page
  [ELocalSavedFilters.VDPResultTable]: ITableWithProviders;

  // Reporting Page
  [ELocalSavedFilters.FrontLineReadyTrendGraph]: IGraph;
  [ELocalSavedFilters.LeastVDPTable]: ITableWithProviders;
  [ELocalSavedFilters.MostVDPTable]: ITableWithProviders;
  [ELocalSavedFilters.MostLeadsTable]: ITableWithProviders;
  [ELocalSavedFilters.DaysOnLotTable]: ITableWithProviders;
  [ELocalSavedFilters.VDPBoostTable]: ITableWithProviders
  [ELocalSavedFilters.LeadDetailsV2]: ITable;

  // Marketing Page
  [ELocalSavedFilters.BudgetROITable]: ITableMarketing;
  [ELocalSavedFilters.TopTenWebsiteSourcesTable]: ITableMarketing;
  [ELocalSavedFilters.VDPsByChannelTable]: ITableMarketing;
  [ELocalSavedFilters.VDPROITable]: ITableMarketing;
  [ELocalSavedFilters.LeadROITable]: ITableMarketing;
}

export const commonTableFilters = {
  dateRange: lastWeek,
  pageSize: defaultPageSize,
};

export const tableFiltersWithProviders: ITableWithProviders = {
  ...commonTableFilters,
  providers: options,
  columnVisibility: {
    vin: false,
  }
};

export const getDefaultFilters = <
  T extends ISavedDefaultFilters[keyof ISavedDefaultFilters]
>(
  filterKey: ELocalSavedFilters,
  defaultValue: T
): T => {
  const savedFilters = localStorage.getItem(ELocalStorageKeys.DefaultFilters);

  if (savedFilters) {
    const formattedFilters: ISavedDefaultFilters =
      JSON.parse(savedFilters) || ({} as T);

    const filterData = formattedFilters[filterKey] as T;

    if (
      filterData &&
      typeof filterData === "object" &&
      "dateRange" in filterData
    ) {
      const { startDate, endDate } = filterData.dateRange || {};

      if (startDate && endDate) {
        filterData.dateRange = {
          endDate: new Date(endDate),
          startDate: new Date(startDate),
        };
      }
    }

    return { ...defaultValue, ...(filterData || {}) };
  }

  return defaultValue;
};

export const setDefaultFilters = <T>(
  filter: ELocalSavedFilters,
  newValues: T
): void => {
  const savedFilters = localStorage.getItem(ELocalStorageKeys.DefaultFilters);

  const formattedFilters: ISavedDefaultFilters = savedFilters
    ? JSON.parse(savedFilters)
    : {};

  const updatedFilters = {
    ...formattedFilters,
    [filter]: newValues,
  };

  const stringifiedFilters = JSON.stringify(updatedFilters);
  localStorage.setItem(ELocalStorageKeys.DefaultFilters, stringifiedFilters);
};
