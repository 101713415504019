import { Box, Text, Icon, Popover, PopoverTrigger, PopoverContent, PopoverArrow, PopoverHeader, PopoverCloseButton, PopoverBody, Button } from '@chakra-ui/react';
import useGetSales, { EModules, TLeadInfoV2Results } from 'api/useGetSales';
import apiDateFormat from 'constants/apiDateFormat';
import { allTime } from 'constants/commonDateRanges';
import { format } from 'date-fns';
import { useAppSelector } from 'hooks/store';
import useUrlQuery from 'hooks/useUrlQuery';
import React from 'react';
import { selectUserData } from 'store/auth/authSlice';
import { CommonUrlParams } from 'utils/urlParams';
import { PiUsersFour } from "react-icons/pi";
import { FaComputer } from "react-icons/fa6";
import { FaWalking } from "react-icons/fa";
import { FaPhoneVolume } from "react-icons/fa6";
import { MdLocalShipping } from "react-icons/md";
import { HiDotsCircleHorizontal } from "react-icons/hi";
import { useNavigate } from 'react-router-dom';
import ERoutes from 'types/Routes';

type LeadInfoProps = {
    vin: string;
    leads: number;
};

const leadTypeToIcon: { [key: string]: JSX.Element } = {
    "WALK_IN": <FaWalking />,
    "PHONE": <FaPhoneVolume />,
    "WHOLESALE": <MdLocalShipping />,
    "REFERRAL": <PiUsersFour />,
    "INTERNET": <FaComputer />,
    "DMS": <FaComputer />, // Assuming DMS uses the same icon as INTERNET
    "DMS Created": <FaComputer />, // Assuming DMS Created uses the same icon as INTERNET
    "WEBSITE_CHAT": <FaComputer />,
    "SHOWROOM": <FaWalking />,
    "OTHER": <HiDotsCircleHorizontal />
  };
  
const LeadIcon = ({ leadType }: { leadType: string }) => {
    const IconComponent = leadTypeToIcon[leadType as keyof typeof leadTypeToIcon] || <HiDotsCircleHorizontal />;
    return <>{IconComponent}</>;
};

const LeadInfo = (props: LeadInfoProps) => {

    const navigate = useNavigate();

    const [isOpen, setIsOpen] = React.useState(false);

    const userAuth = useAppSelector(selectUserData);

    const { getParam } = useUrlQuery<CommonUrlParams>();

    const startDateParams = getParam("startDate");
    const endDateParams = getParam("endDate");
    
    const { data, isFetching } = useGetSales<EModules.LeadInfoV2>({
        module: EModules.LeadInfoV2,
        params: {
          date_from: startDateParams ? format(new Date(startDateParams), apiDateFormat) : format(new Date(allTime.startDate), apiDateFormat),
          date_to: endDateParams ? format(new Date(endDateParams), apiDateFormat) : format(new Date(allTime.endDate), apiDateFormat),
          dealership_uid: userAuth.dealerV2.uid,
          inventory: userAuth.inventory.value,
          vin: props.vin
        },
        enabled: isOpen
    });

    const onClickLead = (lead: TLeadInfoV2Results) => () => {

        if (lead.provider_name) {
            navigate(`${ERoutes.LeadDetails}?&startDate=${startDateParams}&endDate=${endDateParams}&inventory=${userAuth.inventory.value}&vin=${props.vin}&lead_type=${lead.lead_type}&provider_name=${lead.provider_name}`);
        } else {
            navigate(`${ERoutes.LeadDetails}?&startDate=${startDateParams}&endDate=${endDateParams}&inventory=${userAuth.inventory.value}&vin=${props.vin}&lead_type=${lead.lead_type}&provider_name=Unknown`);
        }
    };

    return (
        <>
            <Popover
                isOpen={isOpen}
                onOpen={() => setIsOpen(true)}
                onClose={() => setIsOpen(false)}
            >
                <PopoverTrigger
                    key={props.vin}
                >
                    <Button
                        fontSize="14px"
                        fontWeight="normal"
                        backgroundColor="transparent"
                        _hover={{
                            backgroundColor: "transparent"
                        }}
                    >
                        {props.leads}
                    </Button>
                </PopoverTrigger>
                <PopoverContent>
                    <PopoverArrow />
                    <PopoverHeader>Leads</PopoverHeader>
                    <PopoverCloseButton />
                    <PopoverBody
                        maxHeight={300}
                        overflowY="auto"
                    >
                        <Box>
                            <Box
                                display="flex"
                                flexDirection="column"
                                gap="10px"
                                // maxHeight="300px"
                                // overflowY="auto"
                                padding="0px 10px"
                            >
                                {isFetching && (
                                    <Box>
                                        Loading...
                                    </Box>
                                )}
                                {!isFetching && data && (
                                    <>
                                        {data.map((item, index) => (
                                            <Box
                                                display="flex"
                                                justifyContent="space-between"
                                                gap="10px"
                                            >
                                                <Box
                                                    display="flex"
                                                    alignItems="center"
                                                    gap="5px"
                                                    onClick={onClickLead(item)}
                                                    cursor="pointer"
                                                >
                                                    <Icon fontSize="xl">
                                                        <LeadIcon leadType={item.lead_type} />
                                                    </Icon>
                                                    <Text 
                                                        size="md"
                                                        _hover={{
                                                            color: "blue.500"
                                                        }}
                                                    >
                                                        {item.provider_name || 'Unknown'}
                                                        <Box
                                                            as="span"
                                                            color="gray.500"
                                                            marginLeft="5px"
                                                        >
                                                            {item.customer_phone ? `(${item.customer_phone})` : ''}
                                                        </Box>
                                                    </Text>
                                                </Box>
                                                <Box>
                                                    {item.lead_count.toLocaleString()}
                                                </Box>
                                            </Box>  
                                        ))}
                                    </>
                                )}
                            </Box>
                        </Box>
                    </PopoverBody>
                </PopoverContent>
            </Popover>
        </>
    );
};

export default LeadInfo;