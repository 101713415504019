import { Routes, Route, useLocation } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { ReactComponent as AlertCircleIcon } from "assets/icons/alert-circle.svg";
import { ReactComponent as AlertTriangleIcon } from "assets/icons/alert-triangle.svg";
import { ReactComponent as CheckCircleIcon } from "assets/icons/check-circle.svg";
import ERoutes from "./types/Routes";

import ProtectedRoutes from "./components/ProtectedRoutes";
import UserManagementLayout from "./layouts/UserManagement";
import DynamicUI from "./pages/DynamicUI";
import ForgotPassword from "./pages/ForgotPassword";
import Home from "./pages/Home";
import LoginPage from "./pages/Login";
import Marketing from "./pages/Marketing";
import Merchandising from "./pages/Merchandising";
import NotFound from "pages/NotFound";
import RegisterUser from "./pages/SignUp";

import "react-toastify/dist/ReactToastify.min.css";
import "react-datepicker/dist/react-datepicker.css";
import { useAppDispatch, useAppSelector } from "hooks/store";
import { useEffect } from "react";
import { selectUserData, verifyToken } from "store/auth/authSlice";
import { VDPResultsV2 } from "pages/v2/VDPResults";
import Reporting from "pages/v2/Reporting";
import LeadDetails from "pages/v2/Reporting/components/LeadDetails";

const App = () => {

  const location = useLocation();

  const dispatch = useAppDispatch();

  const userAuth = useAppSelector(selectUserData);

  useEffect(() => {
    // Function to dispatch verifyToken
    const verifyUserToken = () => {
      dispatch(verifyToken({
        email: userAuth.email || '',
        token: userAuth.accessToken || '',
      }));
    };

    // Call verifyUserToken immediately to verify token on component mount or path change
    verifyUserToken();

    // Set up interval to call verifyUserToken every 1 hour
    const intervalId = setInterval(verifyUserToken, 60 * 60 * 1000); // 3600000ms = 1 hour

    // Clean up interval on component unmount or when dependencies change
    return () => clearInterval(intervalId);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname, location.search, dispatch, userAuth.email, userAuth.accessToken]);


  // TODO: ADD A LOADING SCREEN OR OVERLAY
  return (
    <>
      <ToastContainer
        theme="colored"
        icon={({ type }) => {
          switch (type) {
            case "default":
            case "info":
            case "error":
              return <AlertCircleIcon />;

            case "success":
              return <CheckCircleIcon />;

            case "warning":
              return <AlertTriangleIcon />;

            default:
              return null;
          }
        }}
      />
      {/* <Router> */}
        <Routes>
          <Route
            path={`${ERoutes.Home}*`}
            element={
              <ProtectedRoutes>
                <Routes>
                  <Route index element={<Home />} />
                  <Route path={ERoutes.Reporting} element={<Reporting />} />
                  <Route path={ERoutes.LeadDetails} element={<LeadDetails />} />
                  <Route
                    path={ERoutes.Merchandising}
                    element={<Merchandising />}
                  />
                  <Route
                    path={ERoutes.MarketingResults}
                    element={<Marketing />}
                  />
                  <Route path={ERoutes.VDPResults} element={<VDPResultsV2 />} />

                  <Route path="/dynamic-ui" element={<DynamicUI />} />
                  <Route path="*" element={<NotFound />} />
                </Routes>
              </ProtectedRoutes>
            }
          />

          <Route element={<UserManagementLayout />}>
            <Route path={ERoutes.ForgotPassword} element={<ForgotPassword />} />

            <Route path={ERoutes.Login} element={<LoginPage />} />
            <Route path={ERoutes.SignUp} element={<RegisterUser />} />
          </Route>
        </Routes>
      {/* </Router> */}
    </>
  );
};

export default App;
