import { createColumnHelper, VisibilityState } from "@tanstack/react-table";
import { TLeadDetailsV2Results } from "api/useGetSales";
import { TInventoryOptionValue } from "constants/common";
import { format } from "date-fns";
import TAggregatorColumnPDF from "types/TAggregatorColumnPDF";
import TAggregatorColumnXLS from "types/TAggregatorColumnXLS";

const columnHelper = createColumnHelper<TLeadDetailsV2Results>();

export const getColumns = (inventory: TInventoryOptionValue) => [
  // columnHelper.accessor("vin", {
  //   cell: (info) => info.getValue() ?? '-',
  //   header: 'VIN',
  //   id: "vin",
  //   // default to unchecked
  // }),
  // columnHelper.accessor("stock_number", {
  //   cell: (info) => info.getValue() ?? '-',
  //   header: 'Stock #',
  //   id: "stock_number",
  // }),
  // columnHelper.accessor("vehicle", {
  //   id: "vehicle",
  //   cell: (info) => info.getValue() ?? '-',
  //   header: 'Vehicle',
  //   size: 250,
  // }),
  columnHelper.accessor("customer_name", {
    cell: (info) => info.getValue() ?? '-',
    header: 'Customer Name',
    id: "customer_name",
  }),
  columnHelper.accessor("customer_phone", {
    cell: (info) => info.getValue() ?? '-',
    header: 'Customer Phone',
    id: "customer_phone",
  }),
  columnHelper.accessor("lead_type", {
    cell: (info) => info.getValue() ?? '-',
    header: 'Lead Type',
    id: "lead_type",
  }),
  columnHelper.accessor("lead_provider_name", {
    cell: (info) => info.getValue() ?? '-',
    header: 'Lead Provider',
    id: "lead_provider_name",
  }),
  columnHelper.accessor("lead_source_name", {
    cell: (info) => info.getValue() ?? '-',
    header: 'Lead Source',
    id: "lead_source_name",
  }),
  columnHelper.accessor("lead_date", {
    cell: (info) => info.getValue() ? format(new Date(info.getValue()), "MM/dd/yyyy") : '-',
    header: 'Lead Date',
    id: "lead_date",
  })
];

export const getPdfColumns = (
  inventory: TInventoryOptionValue,
  columnVisibility: VisibilityState
): TAggregatorColumnPDF<TLeadDetailsV2Results>[] => {
  const columns: TAggregatorColumnPDF<TLeadDetailsV2Results>[] = [
    // {
    //   label: "VIN",
    //   key: "vin",
    //   func(value): any {
    //     return value || "-";
    //   }
    // },
    // {
    //   label: "Stock #",
    //   key: "stock_number",
    //   func(value): any {
    //     return value || "-";
    //   }
    // },
    // {
    //   label: "Vehicle",
    //   key: "vehicle",
    //   func(value): any {
    //     return value || "-";
    //   }
    // },
    {
      label: "Customer Name",
      key: "customer_name",
      func(value): any {
        return value || "-";
      }
    },
    {
      label: "Customer Phone",
      key: "customer_phone",
      func(value): any {
        return value || "-";
      }
    },
    {
      label: "Lead Type",
      key: "lead_type",
      func(value): any {
        return value || "-";
      }
    },
    {
      label: "Lead Provider",
      key: "lead_provider_name",
      func(value): any {
        return value || "-";
      }
    },
    {
      label: "Lead Source",
      key: "lead_source_name",
      func(value): any {
        return value || "-";
      }
    },
    {
      label: "Lead Date",
      key: "lead_date",
      func(value): any {
        return value ? format(new Date(value), "MM/dd/yyyy") : "-";
        
      }
    },
  ];

  return columns.filter(column => columnVisibility[column.key] !== false); 

};


export const getXLSColumns = (
  inventory: TInventoryOptionValue,
  columnVisibility: VisibilityState
): TAggregatorColumnXLS<TLeadDetailsV2Results>[] => {
   const columns: TAggregatorColumnXLS<TLeadDetailsV2Results>[] = [
    // {
    //   label: "VIN",
    //   key: "vin",
    //   func(value): any {
    //     return value || "-";
    //   }
    // },
    // {
    //   label: "Stock #",
    //   key: "stock_number",
    //   func(value): any {
    //     return value || "-";
    //   }
    // },
    // {
    //   label: "Vehicle",
    //   key: "vehicle",
    //   func(value): any {
    //     return value || "-";
    //   }
    // },
    {
      label: "Customer Name",
      key: "customer_name",
      func(value): any {
        return value || "-";
      }
    },
    {
      label: "Customer Phone",
      key: "customer_phone",
      func(value): any {
        return value || "-";
      }
    },
    {
      label: "Lead Type",
      key: "lead_type",
      func(value): any {
        return value || "-";
      }
    },
    {
      label: "Lead Provider",
      key: "lead_provider_name",
      func(value): any {
        return value || "-";
      }
    },
    {
      label: "Lead Source",
      key: "lead_source_name",
      func(value): any {
        return value || "-";
      }
    },
    {
      label: "Lead Date",
      key: "lead_date",
      func(value): any {
        return value ? format(new Date(value), "MM/dd/yyyy") : "-";
      }
    },
  ];

  return columns.filter(column => columnVisibility[column.key] !== false);
  
};

export const defaultOffset = 0;
