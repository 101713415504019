import { useQuery } from "@tanstack/react-query";
import EDaysOnLot from "types/APIDaysOnLot";
import {
  IDateRanges,
  IDealership,
  IPagination,
  ISearch,
  TInventory,
  TPagination,
} from "types/APIParameters";
import {
  EAPIProviderRanks,
  EProviderInitials,
  IPairProviderRatings,
  TProviders,
} from "types/APIProviderRanks";
import TResultScoreRecentActivity from "types/APIResultScoreKeys";
import { IAPIDefaultResults, IAPIResults } from "types/APIResults";
import fetchData from "utils/fetchData";

export enum EModules {
  BudgetROI = "sales/budget-roi",
  BudgetROIV2 = "v2/sales/budget-roi",
  ChannelStatsReport = "sales/channel-stats-report",
  ChannelStatsReportV2 = "sales/v2/channel-stats-report",
  ChannelSummary = "sales/channel-summary",
  DealRatings = "sales/deal-ratings",
  DealRatingsV2 = "sales/v2/deal-ratings",
  FrontLineGraph = "sales/front-line-graph",
  FrontLineGraphV2 = "sales/v2/front-line-graph",
  FrontLineReadySalesTrend = "sales/front-line-ready-sales-trend",
  NeedsAttention = "sales/needs-attention",
  NeedsAttentionV2 = "sales/v2/needs-attention",
  PreShootReport = "sales/pre-shoot-report",
  PreShootReportV2 = "sales/v2/pre-shoot-report",
  RecentActivityReport = "sales/recent-activity-report",
  RecentActivityReportV2 = "sales/v2/recent-activity-report-dol",
  RecentActivityReportDOL = "sales/recent-activity-report-dol",
  ResultsSummary = "sales/results-summary",
  ResultsSummaryV2 = 'sales/v2/result-summary/sales',
  ResultsSummaryVDPS = "sales/v2/result-summary/vdps",
  Root = "sales/",
  SalesToFrontLineReady = "sales/sales-to-flr",
  SalesToFrontLineReadyV2 = "sales/v2/sales-to-flr",
  TipReport = "sales/tip-report",
  VDPROIV2 = "sales/v2/vdp-roi",
  TopVDPSByModel = "v2/sales/top-vdps-by-model",
  VDPSByVehicle = "v2/sales/vdps-by-vehicle",
  TopTenWebsiteSources = "v2/sales/top-ten-website-sources",
  VDPsByChannelV2 = "v2/sales/vdps-by-channel",
  TipReportV2 = "v2/sales/tip-report",
  VDPByBodyStyleV2 = "v2/sales/vdps-by-bodystyle",
  LeadInfoV2 = "v2/sales/lead-info",
  LeadDetailsV2 = "v2/sales/lead-details",
}

interface IResultsSummary {
  total: number;
  growth: null | number;
}

export type TPreShootReportOrderKeys =
  | "photos_count"
  | "-photos_count"
  | "price"
  | "-price"
  | "dol"
  | "-dol"
  | "vin__year"
  | "-vin__year"
  | "total_vdps"
  | "-total_vdps"
  | "vin__make"
  | "-vin__make"
  | "";

export type TTipReportOrderKeys =
  | "deal_rating"
  | "-deal_rating"
  | "great_deal_rating"
  | "-great_deal_rating"
  | "new_deal_rating"
  | "-new_deal_rating"
  | "price"
  | "-price"
  | "new_price"
  | "-new_price"
  | "price_change"
  | "-price_change"
  | "year"
  | "-year"
  | "age"
  | "-age"
  | "";

export type TDealRatingOrderKeys =
  | "year"
  | "-year"
  | "price"
  | "-price"
  | "dol"
  | "-dol"
  | "stock_number"
  | "-stock_number"
  | "photos_count"
  | "-photos_count"
  | "default_percentage_to_market"
  | "-default_percentage_to_market"
  | "";

export type TRecentActivityReportOrderKeys =
  | "carfax_pulls"
  | "-carfax_pulls"
  | "last_price_change"
  | "-last_price_change"
  | "result_score"
  | "-result_score"
  | "stock_number"
  | "-stock_number"
  | "price"
  | "-price"
  | "photos_count"
  | "-photos_count"
  | "year"
  | "-year"
  | "dol"
  | "-dol"
  | "leads"
  | "-leads"
  | "vdps"
  | "-vdps"
  | "leads/vdps"
  | "-leads/vdps"
  | "default_percentage_to_market"
  | "-default_percentage_to_market"
  | "cars_com_deal_rating"
  | "-cars_com_deal_rating"
  | "cargurus_deal_rating"
  | "-cargurus_deal_rating"
  | "autotrader_deal_rating"
  | "-autotrader_deal_rating"
  | "carfax_deal_rating"
  | "-carfax_deal_rating"
  | "website_deal_rating"
  | "-website_deal_rating"
  | "";

export type TChannelStatsReportOrderKeys =
  | "year"
  | "-year"
  | "dol"
  | "-dol"
  | "photos_count"
  | "-photos_count"
  | "cars_com_price"
  | "-cars_com_price"
  | "cargurus_price"
  | "-cargurus_price"
  | "autotrader_price"
  | "-autotrader_price"
  | "carfax_price"
  | "-carfax_price"
  | "website_price"
  | "-website_price"
  | "cars_com_srps"
  | "-cars_com_srps"
  | "cargurus_srps"
  | "-cargurus_srps"
  | "autotrader_srps"
  | "-autotrader_srps"
  | "carfax_srps"
  | "-carfax_srps"
  | "website_srps"
  | "-website_srps"
  | "cars_com_vdps"
  | "-cars_com_vdps"
  | "cargurus_vdps"
  | "-cargurus_vdps"
  | "autotrader_vdps"
  | "-autotrader_vdps"
  | "carfax_vdps"
  | "-carfax_vdps"
  | "website_vdps"
  | "-website_vdps"
  | "cars_com_srp_vdp_ratio"
  | "-cars_com_srp_vdp_ratio"
  | "cargurus_srp_vdp_ratio"
  | "-cargurus_srp_vdp_ratio"
  | "autotrader_srp_vdp_ratio"
  | "-autotrader_srp_vdp_ratio"
  | "carfax_srp_vdp_ratio"
  | "-carfax_srp_vdp_ratio"
  | "website_srp_vdp_ratio"
  | "-website_srp_vdp_ratio"
  | "cars_com_deal_rating"
  | "-cars_com_deal_rating"
  | "cargurus_deal_rating"
  | "-cargurus_deal_rating"
  | "autotrader_deal_rating"
  | "-autotrader_deal_rating"
  | "carfax_deal_rating"
  | "-carfax_deal_rating"
  | "website_deal_rating"
  | "-website_deal_rating"
  | "";

// v2

export type TLeadDetailsV2Params = TParams & IDateRanges & ISearch & TInventory;

export type TLeadDetailsV2Results = {
  customer_name:      string;
  customer_phone:     string;
  lead_type:          string;
  lead_provider_name: string;
  lead_date:          Date;
  lead_source_name:   string;
}

export type TLeadInfoV2Params = {
  vin: string;
} & IDealership & TInventory & IDateRanges;

export type TLeadInfoV2Results = {
  vin:           string;
  customer_name: string;
  lead_type:     string;
  lead_count:    number;
  customer_phone: string;
  provider_name: string;
}

// common
type TParams = {} & TPagination & IDealership;


type TTipReportV2Params  = {
  dol_from?: number;
  dol_to?: number;
  min_price_change: number;
  max_price_change: number;
  ordering: TTipReportOrderKeys;
  source: EProviderInitials[];
} & TParams & ISearch & IDateRanges;


export type TTipReportV2Results = {
  vin:                              string;
  vehicle:                          string;
  year:                              string;
  make:                              string;
  model:                             string;
  stock_number:                      string;
  age:                               number;

  price:                             number;
  price_change:                      number;
  deal_rating:                       string;
  new_deal_rating:                   string;
  new_price:                         number;
  great_deal_rating:                 string;

  price_at_great_deal_rating:        number;
  price_change_at_great_deal_rating: number;

  cgr_price:                             number;
  cgr_price_change:                      number;
  cgr_deal_rating:                       string;
  cgr_new_deal_rating:                   string;
  cgr_new_price:                         number;
  cgr_great_deal_rating:                 string;

  ccm_price:                             number;
  ccm_price_change:                      number;
  ccm_deal_rating:                       string;
  ccm_new_deal_rating:                   string;
  ccm_new_price:                         number;
  ccm_great_deal_rating:                 string;

  ccm_price_at_great_deal_rating:        number;
  ccm_price_change_at_great_deal_rating: number;

  atc_price:                             number;
  atc_price_change:                      number;
  atc_deal_rating:                       string;
  atc_new_deal_rating:                   string;
  atc_new_price:                         number;
  atc_great_deal_rating:                 string;

  atc_price_at_great_deal_rating:        number;
  atc_price_change_at_great_deal_rating: number;

  cfx_price:                             number;
  cfx_price_change:                      number;
  cfx_deal_rating:                       string;
  cfx_new_deal_rating:                   string;
  cfx_new_price:                         number;
  cfx_great_deal_rating:                 string;

  cfx_price_at_great_deal_rating:        number;
  cfx_price_change_at_great_deal_rating: number;
  
}

interface IGetVDPByBodyStyleParamsV2 extends IDealership, TInventory {
  limit: number;
}

export interface IAPIGetVDPByBodyStyle {
  body_style_label: string;
  total_vdps: number;
}

// channels stats
type TChannelStatsReportParamsV2 = {

} & TParams & IDateRanges & ISearch & TInventory;


export type ChannelStatsReportResultsV2 = {
  vehicle:         string;
  vin:             string;
  stock_number:    string;
  description:     string;
  photos_count:    number;
  year:            number;
  make:            string;
  model:           string;
  dol:             number;
  ga_total_vdps:   number | null;
  ga_total_srps:   number | null;
  ga_srps_vdps:    number;
  ga_price:        string;
  ga_deal_rating:  string;
  cgr_total_vdps:  number;
  cgr_total_srps:  number;
  cgr_srps_vdps:   number;
  cgr_price:       string;
  cgr_deal_rating: string;
  atc_total_vdps:  number;
  atc_total_srps:  number;
  atc_srps_vdps:   number;
  atc_price:       string;
  atc_deal_rating: string;
  ccm_total_vdps:  number;
  ccm_total_srps:  number;
  ccm_srps_vdps:   number;
  ccm_price:       string;
  ccm_deal_rating: string;
  cfx_total_vdps:  number;
  cfx_total_srps:  number;
  cfx_srps_vdps:   number;
  cfx_price:       string;
  cfx_deal_rating: string;
}

// type TAPIChannelStatsReportV2 = IAPIDefaultResults<ChannelStatsReportResultsV2>;

export type SourceType = "website" | "third-party" | "combine";

type VDPROIV2CustomResult<T> = {
  total_sold: number;
  grand_total: number;
} & IAPIResults<T>;

// top vdps by model
type TTopVDPSByModelParamsV2 = {} & IDateRanges & IParams & TInventory;

export type TAPIGetTopVDPSByModel = {
  model: string;
  total_vdps: number;
};

// vdp by vehicle

type TVDPSByVehicleParamsV2 = {} & IParams & TInventory;

export type TAPIVDPByVehicle = {
  stock_number: string;
  total_vdps: number;
  vehicle_make: string;
  vehicle_model: string;
  vehicle_trim: string;
  vehicle_year: string;
}

// top 10 websites
type TTopTenWebsiteSourcesParamsV2 = IParams & IDateRanges & TInventory;

export type TopTenWebsiteSourceV2Result = {
  grand_total_vdps: number;
  grand_total_srps: number;
  results: TopTenWebsiteSourceV2Data[];
}

export type TopTenWebsiteSourceV2Data = {
  source_medium: string;
  total_vdps:    number;
  total_srps:    number;
  rank:          number;
}

// vdproi
type TVDPROIParamsV2 = {
  source_type: SourceType;
} & TParams & IDateRanges & ISearch & TInventory;

export type VDPROIResultV2 = {
  rank: string | null;
  source: string | null;
  total_vdps: number | null;
  // srp_vdp_ratio: number | null;
  sold: number | null;
}

export type TAPIVDPROIV2 = IAPIDefaultResults<VDPROIResultV2[]>;

type VPDsByChannelParamsV2 = IParams & IDateRanges & TInventory;

export type VPDsByChannelDataV2 = {
  rank: number;
  source:          string;
  total_srps:      number;
  total_vdps:      number;
  total_srps_vdps: number;
}

// recent activity report dol
type IRecentActivityReportParamsV2 = {
  result_score?: TResultScoreRecentActivity;
} & TParams & IDateRanges & ISearch & TInventory;

export type IAPIRecentActivityReportV2 = {
  stock_number:                 string;
  price:                        number;
  photos_count:                 number;
  default_percentage_to_market: number;
  last_price_change:            string;
  year:                         number;
  make:                         string;
  model:                        string;
  trim:                         string;
  dol:                          number;
  leads:                        number;
  vdps:                         number;
  total_carfax_pull:            number;
  leads_vdps:                   number;
  result_score:                 number;
  cgr_deal_rating:              string;
  atc_deal_rating:              string;
  ccm_deal_rating:              string;
  cfx_deal_rating:              string;
}

interface IPreShootReportParamsV2 extends TParams, IDateRanges, ISearch, TInventory {}

export type IGetResultsSummaryVDPSParams = {
  last_n_days?: number;
  date_to?: string;
} & IParams & TInventory; 

export type IAPIGetResultsSummaryVDPS = {
  total_vdps: number | null;
  average_vdps_per_vehicle: number | null;
}

export type IGetResultsSummaryParamsV2 = {

} & IParams & IDateRanges & TInventory;

export type IAPIGetResultsSummaryV2 = {
  sales_goal:                     number;
  average_days_to_sell:           number | null;
  sales_pace:                     number | null;
  front_line_ready:               number | null;
  sales_front_line_ready_ratio:   number | null;
  front_line_ready_to_sales_goal: number;
  vdp_pace:                       number | null;
}

export type AverageDaysToSell = {
  total:  number;
  growth: number;
}

type TBudgetROIParamsV2 = IParams & IDateRanges & TInventory & {}

export type TBudgetROIDataV2 = {
  rank: number | null;
  source:           EProviderInitials;
  total_lead_count: number;
  total_vdp_count:  number;
  total_sold_count: number;
  leads_vdps:       number;
  budget:           number;
  cost_vdps:        number;
  cost_leads:       number;
  cost_sale:        number;
  roi_rank:         number;
  monthly_budget:   number;
}

type IDealRatingsV2Params = TParams & IDateRanges & ISearch  &  TInventory &{
  // ordering: TDealRatingOrderKeys;
  // source: EProviderInitials[];
}

export type IAPIDealRatingsV2 = {
  vin:             string;
  stock_number:    string;
  vehicle:         string;
  price:           string;
  photos_count:    number;
  year:            number;
  make:            string;
  model:           string;
  dol:             number;
  cgr_deal_rating: string;
  atc_deal_rating: string;
  ccm_deal_rating: string;
  cfx_deal_rating: string;
}

// end of v2









interface IParams extends IPagination, IDealership {}

interface IGetResultsSummaryParams extends IParams, IDateRanges, TInventory {}

interface IFrontLineGraphParams extends IDealership, IDateRanges, TInventory {}

interface IGetChannelSummaryParams extends IParams, IDateRanges, TInventory {}

interface IPreShootReportParams extends IParams, IDateRanges, ISearch, TInventory {
  ordering: TPreShootReportOrderKeys;
}

interface INeedsAttentionParams extends IParams, IDateRanges, TInventory {}

interface IDealRatingsParams extends IParams, IDateRanges, ISearch {
  ordering: TDealRatingOrderKeys;
  source: EProviderInitials[];
}

interface IRecentActivityReportParams extends IParams, IDateRanges, ISearch {
  ordering: TRecentActivityReportOrderKeys;
  result_score?: TResultScoreRecentActivity;
}

interface IFrontLineReadySalesTrendParams extends IDealership, IDateRanges, TInventory {}

interface ISalesToFrontLineReadyParams extends IDealership, IDateRanges, TInventory {}

interface IChannelStatsReportParams extends IParams, IDateRanges, ISearch {
  source: EProviderInitials[];
}

interface IBudgetROIParams extends IParams, IDateRanges {}

interface ITipReportParams extends IParams, IDateRanges, ISearch {
  dol_range: EDaysOnLot;
  ordering: TTipReportOrderKeys;
  source: EProviderInitials;
}

export interface IAPIFrontLineGraph {
  count: number;
  date_range: [string, string];
  week: string;
}

export interface IAPIGetBudgetROI {
  rank: number | null;
  source: EProviderInitials;
  budget: number;
  total_vdps: number | null;
  "cost/vdp": number;
  "cost/lead": number;
  sold: number;
  "cost/sale": number;
  "lead/vdp": number;
  roi_rank_last_30_days: number | null;
  roi_rank_last_90_days: number | null;
}

export interface IAPIGetSalesRoot {
  dealer: number;
  description: string;
  end_date: null | string;
  id: number;
  photos_count: number;
  price: string | number;
  start_date: string;
  status: string;
  stock_number: string;
  vin: string;
}

export interface IAPIGetResultsSummary {
  total_vdps: IResultsSummary;
  avg_vdp_vehicle: IResultsSummary;
  flr_to_goal: number | null;
  front_line_ready: IResultsSummary;
  sales_flr_radio: IResultsSummary;
  sales_goal: number | null;
  sales_pace: IResultsSummary;
  average_days_to_sell: IResultsSummary;
}

export interface IAPIChannelSummary {
  rank: number;
  source: string;
  "srp/vdp ratio": number;
  total_srp: number;
  total_vdp: number;
}

export interface IAPIPreShootReport {
  vin:           string;
  stock_number:  string;
  price:         string;
  photos:        number;
  vehicle:       string;
  vehicle_year:  number;
  vehicle_make:  string;
  vehicle_model: string;
  dol:           number;
  total_vdps:    number;
}

type TGreen = number;
type TRed = number;
type TYellow = number;
type TResultsScoreBars = [TGreen, TYellow, TRed];

export interface IAPINeedsAttention {
  [EDaysOnLot.ZeroToFifteen]: TResultsScoreBars;
  [EDaysOnLot.SixteenToThirty]: TResultsScoreBars;
  [EDaysOnLot.ThirtyOneToFortyFive]: TResultsScoreBars;
  [EDaysOnLot.FortySixToSixty]: TResultsScoreBars;
  [EDaysOnLot.SixtyAbove]: TResultsScoreBars;
  green_score: number;
  red_score: number;
  needs_pricing: number;
  yellow_score: number;
}

export interface IAPIDealRatings {
  deal_ratings: IPairProviderRatings[];
  default_percentage_to_market: number | null;
  dol: number;
  make: string;
  model: string;
  photos_count: number;
  price: string;
  stock_number: string;
  trim: string;
  year: number;
}

export interface IAPIRecentActivityReport {
  deal_ratings: IPairProviderRatings[];
  default_percentage_to_market: null | number;
  dol: number;
  carfax_pulls: number;
  last_price_change: null | number;
  leads: null | number;
  "leads/vdps": number;
  make: string;
  model: string;
  photos_count: number;
  price: string;
  result_score: number;
  stock_number: string;
  trim: string;
  vdps: null | number;
  year: number;
}

export interface IAPIFrontLineReadySalesTrend {
  count: string;
  date_range: [string, string];
  week: string;
}

export type TSalesToFrontLineReady = [
  {
    quantity: number;
  },
  {
    tippable_autotrader: number;
  },
  {
    tippable_cargurus: number;
  },
  {
    tippable_carfax: number;
  },
  {
    "tippable_cars.com": number;
  },
  {
    zeroleads: number;
    oldestVehicleOnLotDate: string;
  },
  {
    flr: number;
  },
  {
    missing_photo: number;
  },
  {
    less_than_50_vdps: number;
  }
];

export interface IAPISalesToFrontLineReady {
  [EDaysOnLot.ZeroToFifteen]: TSalesToFrontLineReady;
  [EDaysOnLot.SixteenToThirty]: TSalesToFrontLineReady;
  [EDaysOnLot.ThirtyOneToFortyFive]: TSalesToFrontLineReady;
  [EDaysOnLot.FortySixToSixty]: TSalesToFrontLineReady;
  [EDaysOnLot.SixtyAbove]: TSalesToFrontLineReady;
}

export interface IChannelStats {
  deal_rating: EAPIProviderRanks;
  price: number;
  source: TProviders;
  srp_vdp_ratio: number;
  srps: number;
  vdps: number;
}
export interface IAPIChannelStatsReport {
  channel_stats: IChannelStats[];
  description: string;
  dol: number;
  make: string;
  model: string;
  photos_count: number;
  stock_number: string;
  year: number;
}

export interface IAPITipReport {
  age: number;
  deal_rating: EAPIProviderRanks;
  great_deal_rating: EAPIProviderRanks;
  make: string;
  model: string;
  new_deal_rating: EAPIProviderRanks;
  new_price: number;
  price: number;
  price_at_great_deal_rating: number;
  price_change: number;
  price_change_at_great_deal_rating: number;
  stock_number: string;
  trim: string;
  year: string;
  min_price_change: number;
  max_price_change: number;
}

interface IOptions<T extends string> {
  module: EModules;
  params?: T extends EModules.ResultsSummary
    ? IGetResultsSummaryParams
    : T extends EModules.ResultsSummaryV2
    ? IGetResultsSummaryParamsV2
    : T extends EModules.ResultsSummaryVDPS
    ? IGetResultsSummaryVDPSParams
    : T extends EModules.FrontLineGraph | EModules.FrontLineGraphV2
    ? IFrontLineGraphParams
    : T extends EModules.ChannelSummary 
    ? IGetChannelSummaryParams
    : T extends EModules.PreShootReport
    ? IPreShootReportParams
    : T extends EModules.PreShootReportV2
    ? IPreShootReportParamsV2
    : T extends EModules.NeedsAttention | EModules.NeedsAttentionV2
    ? INeedsAttentionParams
    : T extends EModules.DealRatings
    ? IDealRatingsParams
    : T extends EModules.DealRatingsV2
    ? IDealRatingsV2Params
    : T extends EModules.RecentActivityReport
    ? IRecentActivityReportParams
    : T extends EModules.RecentActivityReportV2
    ? IRecentActivityReportParamsV2
    : T extends EModules.FrontLineReadySalesTrend
    ? IFrontLineReadySalesTrendParams
    : T extends EModules.SalesToFrontLineReady
    ? ISalesToFrontLineReadyParams
    : T extends EModules.SalesToFrontLineReadyV2
    ? ISalesToFrontLineReadyParams
    : T extends EModules.ChannelStatsReport
    ? IChannelStatsReportParams
    : T extends EModules.ChannelStatsReportV2
    ? TChannelStatsReportParamsV2
    : T extends EModules.TipReport
    ? ITipReportParams
    : T extends EModules.TipReportV2
    ? TTipReportV2Params
    : T extends EModules.BudgetROI
    ? IBudgetROIParams
    : T extends EModules.BudgetROIV2
    ? TBudgetROIParamsV2
    : T extends EModules.VDPROIV2
    ? TVDPROIParamsV2
    : T extends EModules.TopVDPSByModel
    ? TTopVDPSByModelParamsV2
    : T extends EModules.VDPSByVehicle
    ? TVDPSByVehicleParamsV2
    : T extends EModules.TopTenWebsiteSources
    ? TTopTenWebsiteSourcesParamsV2
    : T extends EModules.VDPsByChannelV2
    ? VPDsByChannelParamsV2
    : T extends EModules.VDPByBodyStyleV2
    ? IGetVDPByBodyStyleParamsV2
    : T extends EModules.LeadInfoV2
    ? TLeadInfoV2Params
    : T extends EModules.LeadDetailsV2
    ? TLeadDetailsV2Params
    : IParams;
  queryKeys?: any[];
  enabled?: boolean;
}

type FetchDataReturnType<T extends IOptions<T>["module"]> =
  T extends EModules.ResultsSummary 
    ? IAPIGetResultsSummary
    : T extends EModules.ResultsSummaryV2
    ? IAPIGetResultsSummaryV2
    : T extends EModules.ResultsSummaryVDPS
    ? IAPIGetResultsSummaryVDPS
    : T extends EModules.FrontLineGraph
    ? IAPIFrontLineGraph[]
    : T extends EModules.FrontLineGraphV2
    ? IAPIResults<IAPIFrontLineGraph[]>
    : T extends EModules.ChannelSummary
    ? IAPIChannelSummary[]
    : T extends EModules.PreShootReport | EModules.PreShootReportV2
    ? IAPIDefaultResults<IAPIPreShootReport[]>
    : T extends EModules.NeedsAttention | EModules.NeedsAttentionV2
    ? IAPINeedsAttention
    : T extends EModules.DealRatings
    ? IAPIResults<IAPIDealRatings[]>
    : T extends EModules.DealRatingsV2
    ? IAPIDefaultResults<IAPIDealRatingsV2[]>
    : T extends EModules.RecentActivityReport
    ? IAPIResults<IAPIRecentActivityReport[]>
    : T extends EModules.RecentActivityReportV2
    ? IAPIDefaultResults<IAPIRecentActivityReportV2[]>
    : T extends EModules.RecentActivityReportDOL 
    ? IAPIResults<IAPIRecentActivityReport[]>
    : T extends EModules.FrontLineReadySalesTrend 
    ? IAPIFrontLineReadySalesTrend[]
    : T extends EModules.SalesToFrontLineReady
    ? IAPISalesToFrontLineReady
    : T extends EModules.SalesToFrontLineReadyV2
    ? IAPISalesToFrontLineReady
    : T extends EModules.ChannelStatsReport
    ? IAPIResults<IAPIChannelStatsReport[]>
    : T extends EModules.ChannelStatsReportV2
    ? IAPIDefaultResults<ChannelStatsReportResultsV2[]>
    : T extends EModules.TipReport
    ? IAPIResults<IAPITipReport[]>
    : T extends EModules.TipReportV2
    ? IAPIDefaultResults<TTipReportV2Results[]>
    : T extends EModules.BudgetROI
    ? IAPIResults<IAPIGetBudgetROI[]>
    : T extends EModules.VDPROIV2
    ? VDPROIV2CustomResult<VDPROIResultV2[]>
    : T extends EModules.TopVDPSByModel
    ? TAPIGetTopVDPSByModel[]
    : T extends EModules.VDPSByVehicle
    ? TAPIVDPByVehicle[]
    : T extends EModules.TopTenWebsiteSources
    ? TopTenWebsiteSourceV2Result
    : T extends EModules.BudgetROIV2
    ? TBudgetROIDataV2[]
    : T extends EModules.VDPsByChannelV2
    ? VPDsByChannelDataV2[]
    : T extends EModules.VDPByBodyStyleV2
    ? IAPIGetVDPByBodyStyle[]
    : T extends EModules.LeadInfoV2
    ? TLeadInfoV2Results[]
    : T extends EModules.LeadDetailsV2
    ? IAPIDefaultResults<TLeadDetailsV2Results[]>
    : IAPIGetSalesRoot;

const useGetSales = <T extends IOptions<T>["module"]>({
  module,
  params,
  queryKeys,
  enabled = true
}: IOptions<T>) => {
  return useQuery({
    refetchOnWindowFocus: false,
    queryFn: getSalesAsync<T>({ module, params }),
    queryKey: [
      "getSales",
      module,
      ...Object.values(params || {}),
      ...(queryKeys || []),
    ],
    keepPreviousData: true,
    enabled: enabled,
  });
};

export const getSalesAsync = <T extends IOptions<T>["module"]>({
  module,
  params,
}: IOptions<T>) =>
  fetchData<FetchDataReturnType<T>>({
    isAuth: true,
    method: "GET",
    params,
    url: `${process.env.REACT_APP_API_URL || ""}api${
      module ? `/${module}` : ""
    }`,
  });

export default useGetSales;
