import { useCallback, useEffect, useMemo, useState } from "react";
import { toast } from "react-toastify";
import { format } from "date-fns";
import useGetSales, {
    TTipReportV2Results,
    EModules,
    TTipReportOrderKeys,
    getSalesAsync,
} from "api/useGetSales";
import { IDateRangeProps } from "components/DateRange";
import { PaginationState } from "components/Table";
import { ESortOrder, ISortState } from "components/Table/types";
import {
    IHeadObjectXLS,
    TActionProps,
    TGeneratePdfFunction,
    TGenerateXLSFunction,
} from "components/Table/components/ActionButtons/types";
import apiDateFormat from "constants/apiDateFormat";
import { useDebounce } from "hooks";
// import { EAPIProviderRanks, TProviders } from "types/APIProviderRanks";
import { IProviderCheckList } from "types/IProviderCheckList";
import { filterColumnsV2 } from "utils/filterColumns";
import {
    ELocalSavedFilters,
    ISavedDefaultFilters,
    commonTableFilters,
    getDefaultFilters,
    setDefaultFilters,
} from "utils/defaultFilters";
import formatOrderingAPI from "utils/formatOrderingAPI";
import useUrlQuery from "hooks/useUrlQuery";
import { TipReportV2UrlParams } from "utils/urlParams";
import { formattedDateRange, onGeneratePDFDefaultType } from "utils/helper";
import { useAppSelector } from "hooks/store";
import { selectUserData } from "store/auth/authSlice";
import { Box, Button, Icon, Text } from "@chakra-ui/react";
import DataTable from "components/v2/DataTable";
import { ActionButtonV2 } from "components/Table/components/ActionButtons";
import { CustomizeProvider } from "components/v2/CustomizeProvider";
import { ColumnSizingState, SortingState, VisibilityState } from "@tanstack/react-table";
import { getColumns, getPdfColumns, getXLSColumns } from "./constants";
import { threeDaysToToday } from "constants/commonDateRanges";
import RangePopover from "components/v2/Popover/RangePopover";
import { FaMoneyBillTrendUp } from "react-icons/fa6";

type TSavedFilters = ISavedDefaultFilters[ELocalSavedFilters.TipReportTableV2];

const TipReportV2 = () => {

    const userAuth = useAppSelector(selectUserData);

    const { getParam, getMultipleParams, setMultipleParams } =
        useUrlQuery<TipReportV2UrlParams>();

    const availableProvider = ['CGR', 'ATC', 'CCM', 'CFX'].filter((pvd) =>
        userAuth.dealerV2.third_party_provider.some((provider) => provider.key === pvd)
    );

    const startDateParams = getParam("startDate");
    const endDateParams = getParam("endDate");
    const sourceParams = getMultipleParams("source") || availableProvider;
    const sortByParams = getParam("sortBy");
    const searchParams = getParam("search");
    const dolFromParams = getParam("dol_from");
    const dolToParams = getParam("dol_to");
    const minPriceChangeParams = getParam("min_price_change");
    const maxPriceChangeParams = getParam("max_price_change");

    const defaultFilters = getDefaultFilters<TSavedFilters>(
        ELocalSavedFilters.TipReportTableV2,
        { ...commonTableFilters, providers: [] }
    );

    const [columnVisibility, setColumnVisibility] = useState<VisibilityState>(
        defaultFilters.columnVisibility && Object.keys(defaultFilters.columnVisibility).length > 0 ? defaultFilters.columnVisibility : { vin: false }
    );

    const [columnSizing, setColumnSizing] = useState<ColumnSizingState>(defaultFilters.columnSizing || {});

    const [dateRange] = useState<IDateRangeProps["value"]>({
        startDate: startDateParams
            ? new Date(startDateParams)
            : threeDaysToToday.startDate,
        endDate: endDateParams
            ? new Date(endDateParams)
            : threeDaysToToday.endDate,
    });

    // const [offset, setOffset] = useState<number>(defaultOffset);
    const [page, setPage] = useState<number>(0);
    const [pageSize, setPageSize] = useState<number>(defaultFilters.pageSize);
    const [providers, setProviders] = useState<IProviderCheckList[]>(
        defaultFilters.providers
    );

    const min_price_change = minPriceChangeParams ? parseInt(minPriceChangeParams) : 0;
    const max_price_change = maxPriceChangeParams ? parseInt(maxPriceChangeParams) : 500;

    const [minPriceChange, setMinPriceChange] = useState<number>(min_price_change);
    const [maxPriceChange, setMaxPriceChange] = useState<number>(max_price_change);

    const [maxRange, setMaxRange] = useState<number>(max_price_change);

    // const [sortBy, setSortBy] = useState<ISortState>(
    //     sortByParams && typeof sortByParams === "string"
    //         ? {
    //             column: sortByParams.replace("-", ""),
    //             order: sortByParams.startsWith("-")
    //                 ? ESortOrder.Descending
    //                 : ESortOrder.Ascending,
    //         }
    //         : {
    //             column: null,
    //             order: ESortOrder.None,
    //         }
    // );

    const [sortBy, setSortBy] = useState<SortingState>(sortByParams && typeof sortByParams === 'string' ? [{
        id: sortByParams.replace("-", ""),
        desc: sortByParams.startsWith("-") ? true : false,
    }] : []);

    const [quickSearch, setQuickSearch] = useState<string>(searchParams ?? "");
    const quickSearchApi = useDebounce<string>(quickSearch, 350);

    useEffect(() => {
        const selectedProviders = providers
            .filter(({ value }) => value)
            .map(({ id }) => id);

        setMultipleParams({
            startDate: format(dateRange.startDate, apiDateFormat),
            endDate: format(dateRange.endDate, apiDateFormat),
            source: selectedProviders,
            inventory: userAuth.inventory.value,
            // search: quickSearchApi,
        });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        dateRange.endDate,
        dateRange.startDate,
        userAuth.inventory.value,
        providers,
        // sortBy.column,
        // sortBy.order,
        // quickSearchApi,
    ]);

    
    useMemo(() => {

        const providers = userAuth.dealerV2.third_party_provider.map((provider) => ({
            id: provider.key,
            label: provider.label,
            value: sourceParams?.includes(provider.key) || false,
        })) as IProviderCheckList[]; 

        setProviders(providers);

    }, [userAuth.dealerV2.third_party_provider]);

    const sortedBy: ISortState = useMemo(() => {

        if (!sortBy.length) {
            return {
                column: null,
                order: ESortOrder.None,
            };
        }
        return {
            column: sortBy[0].id as TTipReportOrderKeys,
            order: sortBy[0].desc ? ESortOrder.Descending : ESortOrder.Ascending,
        };
    }, [sortBy]);

    const fetchParameters = useMemo(
        () => ({
            module: EModules.TipReportV2,
            params: {
                date_from: format(dateRange.startDate, apiDateFormat),
                date_to: format(dateRange.endDate, apiDateFormat),
                dealership_uid: userAuth.dealerV2.uid,
                ...(dolFromParams && !isNaN(parseInt(dolFromParams)) && { 
                dol_from: parseInt(dolFromParams)
                }),
                ...(dolToParams && !isNaN(parseInt(dolToParams)) && { 
                dol_to: parseInt(dolToParams)
                }),
                // dol_range: dolRangeParams as EDaysOnLot,
                // limit: pageSize,
                // offset,
                ordering: formatOrderingAPI<TTipReportOrderKeys>(sortedBy),
                search: quickSearchApi,
                source: providers.filter(({ value }) => value).map(({ id }) => id),
                min_price_change: minPriceChange,
                max_price_change: maxPriceChange,
                page: page + 1,
                page_size: pageSize,
            },
        }),
        [dateRange.startDate, dateRange.endDate, userAuth.dealerV2.uid, dolFromParams, dolToParams, sortedBy, quickSearchApi, providers, minPriceChange, maxPriceChange, page, pageSize]
    );

    const { data, isFetching } = useGetSales<EModules.TipReportV2>(fetchParameters);

    useEffect(() => {
        setDefaultFilters<TSavedFilters>(ELocalSavedFilters.TipReportTableV2, {
            dateRange,
            pageSize,
            providers,
            columnSizing,
            columnVisibility,
        });
    }, [columnSizing, columnVisibility, dateRange, pageSize, providers]);

    const onSort = useCallback((sort: SortingState) => {
        // if (!sort.length) return;
        setSortBy(sort);
        setMultipleParams({
          sortBy: sort.length > 0 ? `${sort[0].desc ? '-' : ''}${sort[0].id}` : '',
        });
    }, []);

    const filteredColumns = useMemo(
        () =>
            filterColumnsV2(
                getColumns(userAuth.inventory.value),
                providers
            ),
        [providers, userAuth.inventory.value]
    );

    const onGenerateXLSX: TActionProps<TGenerateXLSFunction> = useCallback(
        async (generateXLS, setIsLoading, provider) => {
            setIsLoading(true);
            
            try {
                // let hasNextPage = true;
                let collectedResults: TTipReportV2Results[] = [];
                // let mapOffset = defaultOffset;

                let hasNextPage = true;

                let tempPage = 1;

                do {
                    const { next, results } =
                        await getSalesAsync<EModules.TipReportV2>({
                            ...fetchParameters,
                            params: {
                                ...fetchParameters.params,
                                page_size: 1000,
                                page: tempPage,
                                // offset: mapOffset,
                            },
                        })();

                    collectedResults = [...collectedResults, ...results];

                    tempPage++;
                    // console.log('next', next);

                    hasNextPage = next ? true : false;
                    // mapOffset = mapOffset + defaultPageSize;
                } while (hasNextPage);

                let groupedHeadFormatted: [IHeadObjectXLS[], string[]] = [[], []];

                // filter providers
                const filteredProviders = providers.filter(({ value }) => value);

                // filter columns
                const filteredXlsColumns = getXLSColumns(userAuth.inventory.value, columnVisibility).map((column) => {
                        if (column.columns) {
                            column.columns = column.columns.filter(
                                (groupedColumn) =>
                                    !groupedColumn.filterColumnId ||
                                    filteredProviders.some(
                                        (provider) => provider.id === groupedColumn.filterColumnId
                                    )
                            );
                        }
                        return column;
                    })

                    .filter(
                        (column) =>
                            !column.filterColumnId ||
                            filteredProviders.some(
                                (provider) => provider.id === column.filterColumnId
                            )
                    );

                // rest of the code remains the same
                const bodyFormatted = collectedResults.map((values) => {
                    const groupedColumns = filteredXlsColumns
                        // .filter(({ filterColumnId: columnFilterId }) => columnFilterId === filterColumnId)
                        .map(({ columns: groupColumns, func, key, filterColumnId }) => {
                            if (!groupColumns) {
                                
                              const tempValue = (func?.(values[key], values) as string) || (values[key] as string)
                              
                              return tempValue === 'nan' ? '' : tempValue;
                              // return (
                              //       (func?.(values[key], values) as string) ||
                              //       (values[key] as string)
                              //   );
                            } else {
                                return groupColumns.map(({ func, key }) => {
                                    // console.log('func', values);

                                    const tempValue = (func?.(values[key], values) as string) || (values[key] as string)
                                    
                                    return tempValue === 'nan' ? '' : tempValue;
                                    // return (
                                    //     (func?.(values[key], values) as string) ||
                                    //     (values[key] as string)
                                    // );
                                });
                            }
                        });

                    return groupedColumns.flat();
                });

                for (
                    let indexHeadColumn = 0;
                    indexHeadColumn < filteredXlsColumns.length;
                    indexHeadColumn++
                ) {
                    const {
                        colspan,
                        label,
                        columns: groupedColumns,
                    } = filteredXlsColumns[indexHeadColumn];

                    groupedHeadFormatted[0].push({ colspan, label });

                    if (groupedColumns && groupedColumns.length) {
                        groupedColumns.map(({ label }) =>
                            groupedHeadFormatted[1].push(label)
                        );
                    }
                }

                generateXLS(
                    {
                        head: groupedHeadFormatted,
                        body: bodyFormatted,
                    },
                    "tip-report.xls"
                );
                setIsLoading(false);
            } catch (err) {
                console.log("err", err);
                toast.error("Can't export XLS file. Please contact your admin");
            }
        },
        [fetchParameters, providers, userAuth.inventory.value, columnVisibility]
    );

    const onGeneratePDF: TActionProps<TGeneratePdfFunction> = useCallback(
        async (onGeneratePDF, setIsLoading, provider) => {
            setIsLoading(true);

            console.log("provider", provider);

            try {
                // let hasNextPage = true;
                let collectedResults: TTipReportV2Results[] = [];
                // let mapOffset = defaultOffset;

                let hasNextPage = true;

                let tempPage = 1;

                do {
                    const { next, results } =
                        await getSalesAsync<EModules.TipReportV2>({
                            ...fetchParameters,
                            params: {
                                ...fetchParameters.params,
                                page: tempPage,
                                ...(provider && { source: [provider.id] }),
                                // offset: mapOffset,
                            },
                        })();

                    collectedResults = [...collectedResults, ...results];

                    tempPage++;
                    // console.log('next', next);

                    hasNextPage = next ? true : false;
                    // mapOffset = mapOffset + defaultPageSize;
                } while (hasNextPage);

                const filteredPDFColumns = getPdfColumns(userAuth.inventory.value, columnVisibility).filter(
                    ({ filterColumnId }) =>
                        !filterColumnId || filterColumnId === provider?.id
                );

                const bodyFormatted = collectedResults.map((values) => {
                    return filteredPDFColumns.map(
                        ({ func, key }) =>
                            func?.(values[key], values) || (values[key] as string)
                    );
                });

                // const dateRangeTitle = formattedDateRange(dateRange);

                const pdfDate = format(new Date(), "MMM d, yyyy"); // formattedDateRange(dateRange);

                const dealerName = userAuth.dealerV2.name.replace(/\s+/g, '').toLowerCase();

                onGeneratePDF(
                    {
                        head: [filteredPDFColumns.map(({ label }) => label)],
                        body: bodyFormatted,
                        ...onGeneratePDFDefaultType(
                            provider
                                ? `Tip Report - ${provider.label}`
                                : `Tip Report`,
                                pdfDate
                        ),
                    },
                    provider
                        ? `${dealerName}-tip-report-${provider.label.toLowerCase()}.pdf`
                        : `${dealerName}-tip-report.pdf`,
                    {
                        orientation: "landscape",
                        compress: true,
                    }
                );
                setIsLoading(false);
            } catch (err) {
                toast.error("Can't export PDF file. Please contact your admin");
            }
        },
        [userAuth.inventory.value, userAuth.dealerV2.name, columnVisibility, fetchParameters]
    );

    const onProviderChange = (selectedProvider: IProviderCheckList) => {
        setProviders((prevProviders) =>
            prevProviders.map((provider) =>
                provider.id === selectedProvider.id
                    ? { ...provider, value: !provider.value }
                    : provider
            )
        );
        setSortBy([]);
    };

    // const onDateChange = (newValue: [Date, Date]) => {
    //     // setDateRange({ startDate, endDate });
    //     setDateRange({
    //         startDate: set(newValue[0], { hours: 0, minutes: 0, seconds: 0 }),
    //         endDate: set(newValue[1], { hours: 23, minutes: 59, seconds: 59 }),
    //     });
    // };

    const handleRangeChange = (range: number[]) => {
        setMinPriceChange(range[0]);
        setMaxPriceChange(range[1]);
    };

    const onPageChange = useCallback((pagination: PaginationState) => {
        setPage(pagination.pageIndex);
        setPageSize(pagination.pageSize);
    }, []);

    const onSearch = useCallback((search: string) => {
        setQuickSearch(search);
        setPage(0);
        setMultipleParams({
          search: search,
        });
    }, []);

    const columnSidebarChilrend = () => {
        return (
            <Box display="flex" flexFlow="column" gap="10px">
                <Text fontWeight="600">Providers</Text>
                <CustomizeProvider 
                    providers={providers} 
                    onChange={onProviderChange} 
                />
            </Box>
        );
    };

    const conttroller = () => {
        return (
            <Box
                display="flex"
                gap="15px"
                justifyContent="space-between"
                flex="1"
                alignItems="center"
            >
                <Box display="flex" alignItems="center" gap="15px" flex="1">
                    <RangePopover
                        defaultValue={[minPriceChange, maxPriceChange]}
                        header="Price Change"
                        onChange={handleRangeChange}
                        max={maxRange}
                        onMaxChange={setMaxRange}
                        onReset={() => {
                            setMaxRange(500);
                            setMinPriceChange(0);
                            setMaxPriceChange(500);
                        }}
                    >
                        <Button 
                            leftIcon={<Icon as={FaMoneyBillTrendUp} color="#8bb9a8" fontSize="large" />}
                            size="md"
                            border="1px solid #b3b8db"
                            backgroundColor="#ffffff"
                            fontSize="14px"
                            padding="10px 16px"
                            // height="43px"
                        >
                            Price Change
                        </Button>
                  </RangePopover>
                </Box>
                <Box>
                    <ActionButtonV2
                        actionConfig={{
                            isDownloadXLS: true,
                            isProviderPrint: true,
                            isPrintPDF: true,
                            isEmailPDF: true,
                            isEmailXLS: true,
                            isDownloadPDF: true,
                        }}
                        providers={providers}
                        onDownloadPDF={onGeneratePDF}
                        onDownloadXLS={onGenerateXLSX}
                        onGeneratePDF={onGeneratePDF}
                    />
                </Box>
            </Box>
        );
    };

    return (
        <>
            <Box display="flex" flexDirection="column" gap="15px">
                {/* <DateRange
                    onChange={onDateChange}
                    quickOptions={commonDateRanges}
                    value={dateRange}
                /> */}
                <DataTable<TTipReportV2Results>
                    data={data?.results || []}
                    rowCount={data?.count || 0}
                    onPageChange={onPageChange}
                    sort={sortBy}
                    onSort={onSort}
                    columns={filteredColumns as any}
                    pageIndex={page}
                    pageSize={pageSize}
                    isLoading={isFetching}
                    conttroller={conttroller()}
                    onSearch={onSearch}
                    search={quickSearch}
                    pageCount={data?.total_pages}
                    onColumnVisibilityChange={setColumnVisibility}
                    columnVisibility={columnVisibility}
                    onColumnSizingChange={setColumnSizing}
                    columnSizing={columnSizing}
                    showColumns={true}
                    // onUpdateData={updateData}
                    columnsSidebarChildren={columnSidebarChilrend()}
                    triggerButtonType="icon"
                />
            </Box>
        </>
    );
};
export default TipReportV2;
